/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

body[data-sidebar=dark] .vertical-menu,
body[data-sidebar=dark] .navbar-brand-box {
  background: url(/assets/images/agrinet-menu-bg.jpg) !important;
  background-position: top center !important;
  background-size: inherit !important;
  background-repeat: no-repeat !important;
}

.sc-ion-alert-ios-s {
  height: 100%;
  width: 100%;
}

.ion-overlay-wrapper {
  position: static;
  transform: none;
}

// .ng-select.ng-select-single .ng-select-container {
//   min-height: 28.90px;
//   height: 28.90px;
// }

#addDrone .ng-select.ng-select-single .ng-select-container,
#addEditPrice .ng-select.ng-select-single .ng-select-container {
  min-height: 38.36px;
  height: 38.36px;
}

#addDrone .ng-dropdown-panel .ng-dropdown-panel-items,
#addEditPrice .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 150px !important;
}

.deletemodalFooter {
  button {
    min-width: 100px;
  }
}
.loader-table {
  overflow: hidden;
}
.centr{
  display: flex;
  justify-content: center;
}